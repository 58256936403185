import request from '@/plugins/http'

export function getAccountAmount() {
    return request.get('/api/finance/getAccountAmount');
}

export function refreshMoneySummary() {
    return request.post('/api/finance/refreshMoneySummary');
}


