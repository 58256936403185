<template>
  <div>
    <div class="image-upload-list" v-for="item in upload_file.voucherList">
      <template v-if="item.status === 'finished'">
        <img :src="item.url" alt="">
        <div class="image-upload-list-cover">
          <Icon type="ios-eye-outline" @click.native="handleView(item.url)"></Icon>
          <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
        </div>
      </template>
      <template v-else>
        <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
      </template>
    </div>
    <Upload
        ref="upload_info"
        :show-upload-list="false"
        :default-file-list="upload_file.list"
        :on-success="handleSuccess"
        :format="['jpg','jpeg','png']"
        :max-size="2048"
        :on-format-error="handleFormatError"
        :on-exceeded-size="handleMaxSize"
        :before-upload="handleBeforeUpload"
        :on-error = "getErrorInfo"
        type="drag"
        :action="upload_file.upload_host"
        :headers="upload_file.headers"
        :data="upload_file.file_data"
        style="display: inline-block;width:58px;">
      <div style="width: 58px;height:58px;line-height: 58px;">
        <Icon type="ios-camera" size="20"></Icon>
      </div>
    </Upload>
    <Modal title="图片预览" :styles="{top: '20px'}" width="720" v-model="visible" footer-hide>
      <img :src="fileUrl" v-if="visible" style="width: 100%"  alt=""/>
    </Modal>
  </div>
</template>

<script>
import {oss} from '@/plugins/oss';

export default {
  name: "ImageUpload",
  data() {
    return {
      visible: false,
      fileUrl:'',
      upload_file: {
        headers: {
          Authorization: '',
          AuthType: 1, //1表示网站
        },
        file_data: {},
        upload_host: '',
        list: [{
          'url':'https://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_dev/9528/images/sale/2021/XS0000003820120830173930_PBfJE.jpeg',
        }],
        voucherList: []
      },
    }
  },
  props: {
    'uploadParam': Object,
  },
  methods: {
    getErrorInfo(error, file, fileList) {
      console.log(error);
    },
    handleRemove (file) {
      const fileList = this.$refs.upload_info.fileList;
      this.$refs.upload_info.fileList.splice(fileList.indexOf(file), 1);
      this.upload_file.list.splice(fileList.indexOf(file), 1);
      this.$emit('fileList', this.$refs.upload_info.fileList);
      this.upload_file.voucherList = this.$refs.upload_info.fileList;
    },
    handleView (url) {
      this.fileUrl = url;
      this.visible = true;
    },
    handleSuccess(res, file, fileList) {
      if (res.err_no == 0) {
        file.url = res.results.url;

        let obj = {};
        obj.url = file.url;
        obj.is_upload = true;
        obj.name = file.name;
        this.upload_file.list.push(obj);
        this.$emit('fileList', this.upload_file.list);
      }
    },
    resetImgUpload() {
      this.$refs.upload_info.fileList = [];
      this.upload_file.voucherList = [];
      this.upload_file.list = [];
    },
    handleBeforeUpload(file) {
      for (let i = 0; i < this.$refs.upload_info.fileList.length; i++) {
        if (this.$refs.upload_info.fileList[i].is_upload && file.name == this.$refs.upload_info.fileList[i].name) {
          this.$Notice.warning({
            title: '文件重复上传!!!'
          });
          return false;
        }
      }

      const check = this.$refs.upload_info.fileList.length >= this.uploadParam.list_length;
      if (check) {
        this.$Notice.warning({
          title: '上传文件数量请限制' + this.uploadParam.list_length.toString() + '个以内'
        });

        this.$refs.upload_info.fileList = this.$refs.upload_info.fileList.slice(0, this.uploadParam.list_length)
      }

      if (!check) {
        this.upload_file.voucherList = this.$refs.upload_info.fileList;
        return new Promise((resolve, reject) => {
          //加前缀防止相同名称文件替换了
          let fileName = this.uploadParam.prefix + file.name;
          let info = oss.ossInfo(fileName);
          this.upload_file.upload_host = info.host;
          this.upload_file.file_data = info;
          resolve(file);
        });
      }
      return !check;
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: '上传图片文件过大',
        desc: '上传图片  ' + file.name + ' 太大, 请不要超过 2M.'
      });
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '文件 ' + file.name + ' 格式不正确, 请选择 jpg 或者 png.'
      });
    },
    setOssSignature() {
      if (oss.expire < parseInt(new Date().getTime().toString().substr(0,10))
          || (this.uploadParam.upload_type != oss.upload_type) || (this.uploadParam.module_type != oss.module_type)) {
        let param = {
          type: this.uploadParam.upload_type,//2表示凭证上传
          module_type: this.uploadParam.module_type,
        };
        oss.getSignature(param);
      }
    }
  },
  created() {
    this.upload_file.list = this.uploadParam.list;
  },
  mounted() {
    this.upload_file.headers.Authorization = localStorage.getItem('api_token');
    this.upload_file.voucherList = this.$refs.upload_info.fileList;

    this.setOssSignature();
  }
}
</script>

<style scoped>
.image-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  margin-right: 4px;
}

.image-upload-list img {
  width: 100%;
  height: 100%;
}

.image-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .6);
}

.image-upload-list:hover .image-upload-list-cover {
  display: block;
}

.image-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
  position: relative;
  top:50%;
  transform:translateY(-50%);
}
</style>
