<template>
  <div id="main">
    <div id="panel-header">
      <span class="top-title">运费流水单</span>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="formItem" :model="formItem" inline>
          <FormItem prop="main_no">
            <i-input @on-blur="searchSubmit" v-model="formItem.main_no" placeholder="请填写来源单号" class="w-200"/>
          </FormItem>

          <FormItem prop="status">
            <Select @on-change="searchSubmit" v-model="formItem.status" placeholder="请选择订单状态" class="w-200">
              <Option v-for="item in filter.orderStatus" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="created_at">
            <DatePicker type="daterange" @on-change="timeChange" placement="bottom-end"
                        placeholder="请选择订单时间" class="w-200" :value="formItem.created_at"
                        :options="dateRangeOptions"></DatePicker>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('formItem')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <div id="buy_settlement_list">
      <Table :loading="common.list_loading" :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="flow_id">
          <strong><span @click="viewItem(row)" class="pointer">{{ row.flow_id }}</span></strong>
        </template>

        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ (row.status == 1) ? "有效" : "无效" }}</span>
        </template>

        <template slot-scope="{ row }" slot="money">
          <strong><span :style="(row.status != 1)?getStatusColor(row.status):''">{{ row.money }}</span></strong>
        </template>

        <template slot-scope="{ row }" slot="client_name">
          <span v-show="row.client_name">{{ row.client_name }}({{ row.simple_name }})</span>
        </template>

        <template slot-scope="{ row }" slot="created_at">
          <span>{{ row.created_at.substring(0, 10) }}</span>
        </template>

        <template slot-scope="{ row }" slot="action">
          <Button v-show="row.status!=0" type="text" size="small" style="color:#19be6b" @click="viewItem(row)" class="fs-12">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
          <span v-show="row.status==0" class="disabled-color fs-12">------</span>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.page_size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>
    </div>

    <!--查看-->
    <Modal v-model="view.modal" :title="view.title" width="960">
      <div class="modalForm">
        <Form ref="view.item" :model="view.item" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="流水单号" prop="flow_id">
                <span>{{ view.item.flow_id }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="关联单号" prop="main_no">
                <span>{{ view.item.main_no }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="说明" prop="desc">
                <span>{{ view.item.desc }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="客户" prop="client_name">
                <span>{{ view.item.client_name }}({{ view.item.simple_name }})</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="订单费用" prop="order_money">
                <span>{{ view.item.order_money }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="额外费用" prop="extra_money">
                <span>{{ view.item.extra_money }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="总金额" prop="money">
                <span class="main-font-color"><strong>{{ view.item.money }}</strong></span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="流水时间" prop="created_at">
                <span>{{ view.item.created_at }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="流水凭证" prop="certificate">
                <div class="pointer image-upload-list" v-if="view.item.certificate" v-for="(item, key)  in view.item.certificate">
                  <img :src="item.url" alt="" @click="imgShow(key, view.item.certificate)" style="width:100%;height:100%">
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetViewItem(view.item)">取消</Button>
      </div>
    </Modal>

    <!--图片预览-->
    <ImagePreview ref="image_preview"/>
  </div>
</template>

<script>
import '@/css/common.css';
import ImagePreview from '@/components/common/Image/ImagePreview';

export default {
  name: "LogisticsSettlement",
  data() {
    return {
      dateRangeOptions: {
        shortcuts: [
          {
            text: '本周',
            value() {
              const currentDate = new Date();
              //返回date是一周中的某一天
              let week = currentDate.getDay();
              //一天的毫秒数
              let millisecond = 1000 * 60 * 60 * 24;
              //减去的天数
              let minusDay = week != 0 ? week - 1 : 6;
              //本周 周一
              const monday = new Date(currentDate.getTime() - (minusDay * millisecond));
              //本周 周日
              const sunday = new Date(monday.getTime() + (6 * millisecond));
              return [monday, sunday];
            }
          },
          {
            text: '本月',
            value() {
              //获取当前时间
              const currentDate = new Date();
              //获得当前月份0-11
              let currentMonth = currentDate.getMonth();
              //获得当前年份4位年
              let currentYear = currentDate.getFullYear();
              //求出本月第一天
              const firstDay = new Date(currentYear, currentMonth, 1);

              //当为12月的时候年份需要加1
              //月份需要更新为0 也就是下一年的第一个月
              if (currentMonth == 11) {
                currentYear++;
                currentMonth = 0; //就为
              } else {
                //否则只是月份增加,以便求的下一月的第一天
                currentMonth++;
              }

              //一天的毫秒数
              let millisecond = 1000 * 60 * 60 * 24;
              //下月的第一天
              let nextMonthDayOne = new Date(currentYear, currentMonth, 1);
              //求出上月的最后一天
              const lastDay = new Date(nextMonthDayOne.getTime() - millisecond);

              return [firstDay, lastDay];
            }
          },
          {
            text: '本季度',
            value() {
              let now = new Date(); //当前日期
              let nowYear = now.getFullYear(); //当前年
              let nowMonth = now.getMonth(); //当前月

              let quarterStartMonth = 0;
              if (nowMonth < 3) {
                quarterStartMonth = 0;
              }
              if (2 < nowMonth && nowMonth < 6) {
                quarterStartMonth = 3;
              }
              if (5 < nowMonth && nowMonth < 9) {
                quarterStartMonth = 6;
              }
              if (nowMonth > 8) {
                quarterStartMonth = 9;
              }

              const quarterStartDate = new Date(nowYear, quarterStartMonth, 1);

              let quarterEndMonth = quarterStartMonth + 3;

              //一天的毫秒数
              let millisecond = 1000 * 60 * 60 * 24;
              //下月的第一天
              let nextMonthDayOne = new Date(nowYear, quarterEndMonth, 1);
              //求出上月的最后一天
              const quarterEndDate = new Date(nextMonthDayOne.getTime() - millisecond);

              return [quarterStartDate, quarterEndDate];
            }
          }
        ]
      },
      filter: {
        orderStatus: [
          {
            id: 1,
            name: '有效'
          },
          {
            id: 0,
            name: '无效'
          }
        ],
      },
      formItem: {
        status: '',
        created_at: '',
        main_no: '',
      },
      list: {
        page_size_key: 'logistics_settlement_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        columns: [
          {
            title: '流水单号',
            key: 'flow_id',
            slot: 'flow_id',
            width: 210,
          },
          {
            title: '说明',
            key: 'desc',
            align: 'center',
          },
          {
            title: '来源单号',
            key: 'main_no',
            width: 180,
            align: 'center',
          },
          {
            title: '客户',
            key: 'client_name',
            slot: 'client_name',
            align: 'center',
          },
          {
            title: '订单状态',
            key: 'status',
            align: 'center',
            slot: 'status',
            width: 100,
          },
          {
            title: '总金额',
            key: 'money',
            align: 'center',
            slot: 'money',
            width: 100,
          },
          {
            title: '流水时间',
            slot: 'created_at',
            key: 'created_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 100,
          },
        ],
        data: [],
        total: 0,
        page_size: 0,
        page: 1,
      },
      view: {
        modal: false,
        item: {},
        title: "查看明细"
      },
      common: {
        list_loading: false,
      }
    };
  },
  methods: {
    //查看图片
    imgShow(value, img_info) {
      this.$refs.image_preview.imgPreview(value, img_info);
    },
    //获取状态的颜色
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'color:#19be6b';
          break;
        case 0:
          rs = 'color:#c5c8ce';
          break;
        default:
          rs = '';
          break;
      }
      return rs;
    },
    searchSubmit() {
      this.list.page = 1;
      this.getMainList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getMainList();
    },
    //获取采购流水单信息
    getMainList() {
      this.common.list_loading = true;
      let param = {
        page: this.list.page,
        size: this.list.page_size,
        status: this.formItem.status,
        created_at: this.formItem.created_at,
        main_no: this.formItem.main_no,
      };
      this.$axios.post('/api/finance/getLogisticsFlowList', param).then((res) => {
        this.common.list_loading = false;
        if (res.data.err_no == 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].extra_money = parseFloat(res.data.results.list[i].extra_money);
            res.data.results.list[i].order_money = parseFloat(res.data.results.list[i].order_money);
            res.data.results.list[i].money = parseFloat(res.data.results.list[i].money);
          }
          this.list.data = res.data.results.list;
          this.list.total = res.data.results.total;
          this.list.page_size = res.data.results.size;
        }
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    pageSizeChange(page_size) {
      this.list.page_size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getMainList();
    },
    //取消
    handleResetViewItem(item) {
      this.view.modal = false;
    },
    //查看
    viewItem(row) {
      this.view.modal = true;
      this.view.item = row;
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.formItem.created_at = dateRange;
      } else {
        this.formItem.created_at = "";
      }

      this.searchSubmit();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.page_size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  created() {
    this.aCheck.plateName = 'financial_center';
  },
  mounted() {
    this.setSelectedPageSize();
    this.clientFormItembak = this.clientFormItem;
    this.getMainList();
  },
  components: {
    ImagePreview
  }
};
</script>

<style scoped>
#main {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

#modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#buy_settlement_list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#buy_settlement_list .page {
  margin-top: 16px;
}

</style>
<style>
#main .ivu-card {
  background-color: #fafafa;
}

#main .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#main .ivu-form-item {
  margin-bottom: 0;
}

#buy_settlement_list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#buy_settlement_list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#buy_settlement_list .ivu-page {
  float: right;
}

#buy_settlement_list .ivu-table-wrapper {
  height: auto;
}

#buy_settlement_list .ivu-table-fixed-body {
  min-height: auto;
}

#buy_settlement_list .ivu-table-overflowX, #buy_settlement_list .ivu-table-tip {
  overflow-x: hidden;
}

#buy_settlement_list .ivu-spin-fix {
  top: 40px !important;
  height:calc(100% - 40px) !important;
}
</style>
