<template>
  <div id="main">
    <div id="panel-header">
      <span class="top-title">采购退货流水单</span>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form :model="formItem" inline ref="filter_bar">
          <FormItem prop="link_no">
            <i-input @on-blur="searchSubmit" v-model="formItem.link_no" placeholder="请填写关联单号" class="w-200"/>
          </FormItem>

          <FormItem prop="status">
            <Select @on-change="searchSubmit" v-model="formItem.status" placeholder="请选择订单状态" class="w-200">
              <Option v-for="item in filter.orderStatus" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="created_at">
            <DatePicker type="daterange" @on-change="timeChange" placement="bottom-end"
                        placeholder="请选择订单时间" class="w-200" v-model="formItem.created_at"></DatePicker>
          </FormItem>

          <FormItem >
            <Button type="text" class="main-font-color" @click="cancelSearch('filter_bar')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <div id="buy_return_settlement_list">
      <Table :loading="common.list_loading" :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="flow_id">
          <strong><span @click="viewItem(row)" class="pointer">{{ row.flow_id }}</span></strong>
        </template>

        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ (row.status == 1) ? "有效" : "无效" }}</span>
        </template>

        <template slot-scope="{ row }" slot="money">
          <strong><span :style="(row.status != 1)?getStatusColor(row.status):''">{{ row.money }}</span></strong>
        </template>

        <template slot-scope="{ row }" slot="client_name">
          <span>{{ row.client_name }}<span v-if="row.simple_name">({{ row.simple_name }})</span></span>
        </template>

        <template slot-scope="{ row }" slot="link_no">
          <span class="pointer data-copy" @click="copyData(row.main_no)">{{row.main_no}}<Icon type="ios-copy-outline"/></span>
        </template>

        <template slot-scope="{ row }" slot="created_at">
          <span>{{ row.created_at.substring(0, 10) }}</span>
        </template>

        <template slot-scope="{ row }" slot="action">
          <Button  type="text" class="fs-12" size="small" style="color:#19be6b" @click="viewItem(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.page_size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>
    </div>

    <!--查看-->
    <Modal v-model="view.modal" :title="view.title" width="960">
      <div class="modalForm">
        <Form ref="view.item" :model="view.item" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="流水单号" prop="flow_id">
                <span>{{ view.item.flow_id }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="退货单号" prop="main_no">
                <span>{{ view.item.main_no }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="供应商" prop="client_name">
                <span>{{ view.item.client_name }}({{ view.item.simple_name }})</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="说明" prop="desc">
                <span>{{ view.item.desc }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="订单费用" prop="order_money">
                <span>{{ view.item.order_money }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="额外费用" prop="extra_money">
                <span>{{ view.item.extra_money }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="总金额" prop="money">
                <span class="main-font-color"><strong>{{ view.item.money }}</strong></span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="创建时间" prop="created_at">
                <span>{{ view.item.created_at }}</span>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetViewItem(view.item)">取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import * as copy_util from '@/utils/copy';

export default {
  name: "BuyReturnSettlement",
  data() {
    return {
      filter: {
        orderStatus: [
          {
            id: 1,
            name: '有效'
          },
          {
            id: 0,
            name: '无效'
          }
        ],
      },
      formItem: {
        status: '',
        created_at: '',
        link_no: '',
      },
      list: {
        page_size_key: 'buy_return_settlement_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        columns: [
          {
            title: '流水单号',
            key: 'flow_id',
            slot: 'flow_id',
            width: 210,
          },
          {
            title: '说明',
            key: 'desc',
            align: 'center',
          },
          {
            title: '关联单号',
            key: 'main_no',
            slot: 'link_no',
            align: 'center',
          },
          {
            title: '供应商',
            key: 'client_name',
            slot: 'client_name',
            align: 'center',
          },
          {
            title: '订单状态',
            key: 'status',
            align: 'center',
            slot: 'status',
            width: 100,
          },
          {
            title: '总金额',
            key: 'money',
            align: 'center',
            slot: 'money',
            width: 100,
          },
          {
            title: '创建时间',
            slot: 'created_at',
            key: 'created_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 100,
          },
        ],
        data: [],
        total: 0,
        page_size: 0,
        page: 1,
      },
      view: {
        modal: false,
        item: {},
        title: "查看明细"
      },
      common: {
        list_loading: false,
      }
    };
  },
  methods: {
    copyData(data) {
      copy_util.copyData(data, this.$Message);
    },
    //获取状态的颜色
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'color:#19be6b';
          break;
        case 0:
          rs = 'color:#c5c8ce';
          break;
        default:
          rs = '';
          break;
      }
      return rs;
    },
    searchSubmit() {
      this.list.page = 1;
      this.getMainList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getMainList();
    },
    //获取销售流水单信息
    getMainList() {
      this.common.list_loading = true;
      let param = {
        page: this.list.page,
        size: this.list.page_size,
        status: this.formItem.status,
        created_at: this.formItem.created_at,
        link_no: this.formItem.link_no,
      };
      this.$axios.post('/api/finance/getBuyReturnFlowList', param).then((res) => {
        this.common.list_loading = false;
        if (res.data.err_no == 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].extra_money = parseFloat(res.data.results.list[i].extra_money);
            res.data.results.list[i].order_money = parseFloat(res.data.results.list[i].order_money);
            res.data.results.list[i].money = parseFloat(res.data.results.list[i].money);
          }
          this.list.data = res.data.results.list;
          this.list.total = res.data.results.total;
          this.list.page_size = res.data.results.size;
        }
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    pageSizeChange(page_size) {
      this.list.page_size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getMainList();
    },
    //取消
    handleResetViewItem(item) {
      this.view.modal = false;
    },
    //查看
    viewItem(row) {
      this.view.modal = true;
      this.view.item = row;
    },
    //记账结算
    finishSaleSettlement(row) {
      this.$Modal.confirm({
        title: "记账结算",
        content: '确定将该订单的 <span style="color:#ed4014;">记账未结算状态</span> 改为 <span style="color:#ed4014;">记账已结算状态</span> ？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.$axios.post('/api/finance/finishSaleSettlement', {id: row.id}).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getMainList();
            } else {
              this.$Message.error(response.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.formItem.created_at = dateRange;
      } else {
        this.formItem.created_at = "";
      }

      this.searchSubmit();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.page_size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },

  },
  mounted() {
    this.setSelectedPageSize();
    this.clientFormItembak = this.clientFormItem;
    this.getMainList();
  },
  created() {
    this.aCheck.plateName = 'financial_center';
  },
};
</script>

<style scoped>
#main {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

#modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#buy_return_settlement_list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#buy_return_settlement_list .page {
  margin-top: 16px;
}
</style>
<style>
#main .ivu-card {
  background-color: #fafafa;
}

#main .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#main .ivu-form-item {
  margin-bottom: 0;
}

#buy_return_settlement_list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#buy_return_settlement_list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#buy_return_settlement_list .ivu-page {
  float: right;
}

#buy_return_settlement_list .ivu-table-wrapper {
  height: auto;
}

#buy_return_settlement_list .ivu-table-fixed-body {
  min-height: auto;
}

#buy_return_settlement_list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

#buy_return_settlement_list .ivu-spin-fix {
  top: 40px !important;
  height:calc(100% - 40px) !important;
}


</style>
