<template>
  <div class="layout">
    <Layout>
      <Layout>
        <Sider width="200">
          <FinanceMenu ref="financeMenu" @func="getMsgFormMenu" :active="title_seclectd" :menuName="menuName"></FinanceMenu>
        </Sider>
        <Content>
          <NavTag ref="navTag" @func="getMsgFormMenu"></NavTag>
          <PreviewSettlement v-if="title_seclectd == 1"></PreviewSettlement>
          <BuySettlement v-if="title_seclectd == 2"></BuySettlement>
          <SaleSettlement v-if="title_seclectd == 3"></SaleSettlement>
          <BuyReturnSettlement v-if="title_seclectd == 4"></BuyReturnSettlement>
          <SaleReturnSettlement v-if="title_seclectd == 5"></SaleReturnSettlement>
          <OtherSettlement v-if="title_seclectd == 6"></OtherSettlement>
          <LogisticsSettlement v-if="title_seclectd == 7"></LogisticsSettlement>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>
import FinanceMenu from '@/components/menu/FinanceMenu.vue';
import NavTag from '@/components/menu/NavTags.vue';
import BuySettlement from '@/views/finance/BuySettlement.vue';
import SaleSettlement from '@/views/finance/SaleSettlement.vue';
import PreviewSettlement from '@/views/finance/PreviewSettlement.vue';
import BuyReturnSettlement from '@/views/finance/BuyReturnSettlement.vue';
import SaleReturnSettlement from '@/views/finance/SaleReturnSettlement.vue';
import OtherSettlement from '@/views/finance/OtherSettlement.vue';
import LogisticsSettlement from "@/views/finance/LogisticsSettlement";

export default {
  name: "Finance",
  components: {
    FinanceMenu,
    BuySettlement,
    SaleSettlement,
    PreviewSettlement,
    BuyReturnSettlement,
    SaleReturnSettlement,
    OtherSettlement,
    LogisticsSettlement,
    NavTag
  },
  data() {
    return {
      title_seclectd: "1",
      menuName: "Finance",
    };
  },
  mounted() {
    this.title_seclectd = localStorage.getItem(this.menuName) ? localStorage.getItem(this.menuName) : "1";
    this.setNavInfo(this.title_seclectd)
    },
  methods: {
    getMsgFormMenu(data) {
      this.title_seclectd = data;
      this.setNavInfo(data)
    },
    setNavInfo(data) {
      this.$refs.navTag.setNavList(this.$route.path, this.title_seclectd)
      this.$refs.financeMenu.setNavInfo(data)
    }
  },
};
</script>

<style scoped>
.ivu-layout {
  background-color: transparent;
}

.ivu-layout-sider {
  background-color: transparent;
}

.ivu-menu {
  height: 100%;
}

.laypout {
  display: flex;
}
</style>
