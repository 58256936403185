<template>
  <div id="other_settlement">
    <div id="panel-header">
      <span class="top-title">杂项结算</span>
      <Button type="primary" class="client_create" @click="createOrder('create.item')"
              v-if="aCheck.rightCheck('edit_miscellaneous_settlement')">创建流水
      </Button>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="formItem" :model="formItem" inline>
          <FormItem prop="status">
            <Select @on-change="searchSubmit" v-model="formItem.status" placeholder="请选择订单状态" class="w-200">
              <Option v-for="item in filter.orderStatus" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="category_type">
            <Select @on-change="searchSubmit" v-model="formItem.category_type" multiple placeholder="请选择订单类别" filterable class="w-200">
              <Option v-for="item in filter.orderCategoryType" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="inout_type">
            <Select @on-change="searchSubmit" v-model="formItem.inout_type" placeholder="请选择订单类型" filterable class="w-200">
              <Option value="1" :key="1">收入</Option>
              <Option value="-1" :key="-1">支出</Option>
            </Select>
          </FormItem>

          <FormItem prop="created_at">
            <DatePicker type="daterange" @on-change="timeChange" placement="bottom-end" placeholder="请选择订单时间" class="w-200"
                        v-model="formItem.created_at" :options="dateRangeOptions"></DatePicker>
          </FormItem>

          <FormItem prop="pay_type">
            <Select @on-change="searchSubmit" v-model="formItem.pay_type" placeholder="请选择结算状态" class="w-200">
              <Option v-for="item in pay_type" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem style="margin-right: 0;">
            <Button type="text" class="main-font-color" @click="cancelSearch('formItem')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <div id="other_settlement_list">
      <Table :loading="common.list_loading" :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="flow_id">
          <strong><span @click="viewItem(row)" class="pointer">{{ row.flow_id }}</span></strong>
        </template>

        <template slot-scope="{ row }" slot="inout_type">
          <span>{{ (row.inout_type == 1) ? "收入" : "支出" }}</span>
        </template>

        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
        </template>

        <template slot-scope="{ row }" slot="money">
          <strong><span :style="(row.status == 0)?getStatusColor(row.status):''">{{ row.money }}</span></strong>
        </template>

        <template slot-scope="{ row }" slot="pay_type">
          <span :style="getPayTypeColor(row.pay_type)">{{ getPayTypeText(row.pay_type) }}</span>
        </template>
        <template slot-scope="{ row }" slot="flow_time">
          <span>{{ row.flow_time.substring(0, 10) }}</span>
        </template>

        <template slot-scope="{ row }" slot="action">
          <Button v-show="row.pay_type==2 && row.status==1" class="fs-12" type="text" size="small" style="color:#ff9900;"
                  @click="finishSaleSettlement(row)" v-if="aCheck.rightCheck('operate_miscellaneous_settlement')">
            <Icon type="ios-checkmark-circle"/>
            结算
          </Button>

          <Button v-show="row.status==2" type="text" class="fs-12" size="small" style="color:#ed4014;"
                  @click="viewItem(row)" v-if="aCheck.rightCheck('valid_miscellaneous_settlement')">
            <Icon type="ios-checkmark-circle"/>
            生效
          </Button>

          <Dropdown v-show="row.status==2" v-if="aCheck.rightCheck('edit_miscellaneous_settlement')">
            <Button size="small" type="text" class="fs-12">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-show="row.status==2">
                <span @click="edit(row)" class="f12">修改流水</span>
              </DropdownItem>
              <DropdownItem v-show="row.status==2">
                <span @click="deleteInfo(row)" class="f12">删除流水</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Button v-show="row.pay_type!=2  && row.status==1 " type="text" size="small" style="color:#19be6b"
                  @click="viewItem(row)" class="fs-12">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
        </template>
      </Table>
      <div class="page">
        <span v-for="item in list.extra" style="margin-right:20px;float: left;" v-if="item.inout_type != 1">
          <strong>支出: </strong>
          <strong class="main-color">{{ item.money }}</strong>
        </span>
        <span v-for="item in list.extra" style="margin-right:20px;float: left;" v-if="item.inout_type == 1">
          <strong>收入: </strong>
          <strong class="main-color">{{ item.money }}</strong>
        </span>
        <span class="fs12 disabled-color"><Icon type="ios-alert-outline"/> 默认展示有效流水单数据</span>
        <Page :total="list.total" :page-size="list.page_size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>
    </div>

    <!--查看-->
    <Modal v-model="view.modal" :title="view.title" width="960">
      <div class="modalForm">
        <Form :model="view.item" :label-width="100" label-colon>
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="单号">
                <span>{{ view.item.flow_id }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="费用说明" prop="desc">
                <span>{{ view.item.desc }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="类别">
                <span>{{ view.item.category_name }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="类型" prop="type">
                <span>{{ (view.item.inout_type == 1) ? "收入" : "支出" }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="费用" prop="order_money">
                <span>{{ view.item.order_money }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="结算状态" prop="pay_type">
                <span :style="getPayTypeColor(view.item.pay_type)">{{ getPayTypeText(view.item.pay_type) }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="额外费用" prop="extra_money">
                <span>{{ view.item.extra_money }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="总金额" prop="money">
                <span style="font-weight: bold;" class="main-font-color">{{ view.item.money }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="时间" prop="flow_time">
                <span>{{ view.item.flow_time }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="订单状态" prop="status">
                <span :style="getStatusColor(view.item.status)">{{ getStatusText(view.item.status) }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="凭证" prop="certificate">
                <div class="pointer image-upload-list" v-if="view.item.certificate" v-for="(item, key)  in view.item.certificate">
                  <img :src="item.url" alt="" @click="imgShow(key, view.item.certificate)" style="width:100%;height:100%">
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetViewItem(view.item)">取消</Button>
        <Button v-show="view.item.status==2" type="primary" @click="edit(view.item)"
                v-if="aCheck.rightCheck('edit_miscellaneous_settlement')">修改
        </Button>
        <Button v-show="view.item.status==2" type="success" :loading="view.modal_loading"
                @click="validFlowStatus(view.item)" v-if="aCheck.rightCheck('valid_miscellaneous_settlement')">生效
        </Button>
      </div>
    </Modal>

    <!--创建-->
    <Modal v-model="create.modal" :title="create.title" width="960">
      <div class="modalForm">
        <Form ref="create.item" :model="create.item" :label-width="100" :rules="ruleInline" label-colon>
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="单号" prop="flow_id">
                <Input v-model="create.item.flow_id" disabled placeholder="请填写流水单号"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="费用说明" prop="desc">
                <Input v-model="create.item.desc" placeholder="请填写费用说明" maxlength="200"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="类别" prop="category_id">
                <Select v-model="create.item.category_id" placeholder="请选择流水类别">
                  <Option v-for="i in flow_category" :value="i.id">{{ i.name }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="类型" prop="inout_type">
                <Select v-model="create.item.inout_type" placeholder="请选择流水类型">
                  <Option v-for="i in in_out" :value="i.id">{{ i.name }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="费用" prop="order_money">
                <Input v-model="create.item.order_money" placeholder="请填写费用" type="number"
                       maxlength="16"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="结算状态" prop="pay_type">
                <Select v-model="create.item.pay_type" placeholder="请选择结算状态">
                  <Option v-for="i in pay_type" :value="i.id">{{ i.name }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="额外费用" prop="extra_money">
                <Input v-model="create.item.extra_money" placeholder="请填写额外费用" type="number"
                       maxlength="16"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="时间" prop="flow_time">
                <DatePicker :value="create.item.flow_time" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请填写流水生成时间(不填，默认当前时间)" style="width: 355px"
                            @on-change="getEditCreateTime"></DatePicker>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="凭证" prop="certificate">
                <!-- fileList为回传属性    uploadParam用来传值                -->
                <ImageUpload ref="image_upload" @fileList="getFileList" :uploadParam="uploadParam"
                             :key="uploadParam.key"/>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetViewItem(create.item)">取消</Button>
        <Button type="primary" :loading="create.modal_loading" @click="createOtherOrder('create.item')">保存
        </Button>
      </div>
    </Modal>

    <!--图片预览-->
    <ImagePreview ref="image_preview"/>
  </div>
</template>

<script>
import '@/css/common.css';
import ImageUpload from '@/components/common/ImageUpload.vue';
import ImagePreview from '@/components/common/Image/ImagePreview';

export default {
  name: "OtherSettlement",
  data() {
    return {
      dateRangeOptions: {
        shortcuts: [
          {
            text: '本周',
            value() {
              const currentDate = new Date();
              //返回date是一周中的某一天
              let week = currentDate.getDay();
              //一天的毫秒数
              let millisecond = 1000 * 60 * 60 * 24;
              //减去的天数
              let minusDay = week != 0 ? week - 1 : 6;
              //本周 周一
              const monday = new Date(currentDate.getTime() - (minusDay * millisecond));
              //本周 周日
              const sunday = new Date(monday.getTime() + (6 * millisecond));
              return [monday, sunday];
            }
          },
          {
            text: '本月',
            value() {
              //获取当前时间
              const currentDate = new Date();
              //获得当前月份0-11
              let currentMonth = currentDate.getMonth();
              //获得当前年份4位年
              let currentYear = currentDate.getFullYear();
              //求出本月第一天
              const firstDay = new Date(currentYear, currentMonth, 1);

              //当为12月的时候年份需要加1
              //月份需要更新为0 也就是下一年的第一个月
              if (currentMonth == 11) {
                currentYear++;
                currentMonth = 0; //就为
              } else {
                //否则只是月份增加,以便求的下一月的第一天
                currentMonth++;
              }

              //一天的毫秒数
              let millisecond = 1000 * 60 * 60 * 24;
              //下月的第一天
              let nextMonthDayOne = new Date(currentYear, currentMonth, 1);
              //求出上月的最后一天
              const lastDay = new Date(nextMonthDayOne.getTime() - millisecond);

              return [firstDay, lastDay];
            }
          },
          {
            text: '本季度',
            value() {
              let now = new Date(); //当前日期
              let nowYear = now.getFullYear(); //当前年
              let nowMonth = now.getMonth(); //当前月

              let quarterStartMonth = 0;
              if (nowMonth < 3) {
                quarterStartMonth = 0;
              }
              if (2 < nowMonth && nowMonth < 6) {
                quarterStartMonth = 3;
              }
              if (5 < nowMonth && nowMonth < 9) {
                quarterStartMonth = 6;
              }
              if (nowMonth > 8) {
                quarterStartMonth = 9;
              }

              const quarterStartDate = new Date(nowYear, quarterStartMonth, 1);

              let quarterEndMonth = quarterStartMonth + 3;

              //一天的毫秒数
              let millisecond = 1000 * 60 * 60 * 24;
              //下月的第一天
              let nextMonthDayOne = new Date(nowYear, quarterEndMonth, 1);
              //求出上月的最后一天
              const quarterEndDate = new Date(nextMonthDayOne.getTime() - millisecond);

              return [quarterStartDate, quarterEndDate];
            }
          }
        ]
      },
      filter: {
        orderStatus: [
          {
            id: 2,
            name: '待生效'
          },
          {
            id: 1,
            name: '有效'
          },
          {
            id: 0,
            name: '无效'
          }
        ],
        orderPayType: [
          {
            id: 1,
            name: '现金结算'
          },
          {
            id: 2,
            name: '未结算(记账)'
          },
          {
            id: 3,
            name: '已结算(记账)'
          },
          // {
          //     id: 4,
          //     name: '记账退货，无需结算'
          // }
        ],
        orderCategoryType: [],
      },
      formItem: {
        status: '',
        inout_type: '',
        created_at: '',
        category_type: '',
        pay_type: ''
      },
      list: {
        page_size_key: 'other_settlement_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        columns: [
          {
            title: '流水单号',
            key: 'flow_id',
            slot: 'flow_id',
            width: 210,
          },
          {
            title: '说明',
            key: 'desc',
            align: 'center',
          },
          // {
          //     title: '类别',
          //     key: 'category_name',
          //     align: 'center',
          // },

          {
            title: '结算状态',
            key: 'pay_type',
            align: 'center',
            slot: 'pay_type',
          },
          {
            title: '类型',
            key: 'inout_type',
            align: 'center',
            slot: 'inout_type',
          },
          // {
          //     title: '订单费用',
          //     align: 'center',
          //     key: 'order_money',
          // },
          // {
          //     title: '额外费用',
          //     key: 'extra_money',
          //     align: 'center',
          // },
          {
            title: '订单状态',
            key: 'status',
            align: 'center',
            slot: 'status',
          },
          {
            title: '总金额',
            key: 'money',
            align: 'center',
            slot: 'money',
          },
          {
            title: '流水时间',
            slot: 'flow_time',
            key: 'flow_time',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 150,
          },
        ],
        data: [],
        total: 0,
        page_size: 0,
        page: 1,
        extra: [],
      },
      view: {
        modal: false,
        modal_loading: false,
        item: {},
        title: "查看明细"
      },
      create: {
        modal: false,
        modal_loading: false,
        item: {
          id: '',
          flow_id: '',
          pay_type: 1,
          order_money: '',
          extra_money: 0,
          money: '',
          desc: '',
          flow_time: '',//流水创建时间
          inout_type: '',
          category_id: '',
          certificate: [],
        },
        title: "操作流水信息",
        img_url: '',
        file: '',
      },
      uploadParam: {
        list_length: 3,
        upload_type: 2,//2表示凭证上传
        module_type: 2,//2表示流水
        prefix: '',
        list: [],
        key: 0,
      },
      pay_type: [
        {
          "id": 1,
          "name": "现金",
        },
        {
          "id": 2,
          "name": "记账，未结算",
        },
        {
          "id": 3,
          "name": "记账，已结算",
        },
        // {
        //     "id": 4,
        //     "name": "记账，无需结算",
        // }
      ],
      in_out: [
        {
          "id": -1,
          "name": "支出",
        },
        {
          "id": 1,
          "name": "收入",
        },
      ],
      ruleInline: {
        pay_type: [
          {required: true, message: '请选择结算状态'},
        ],
        category_id: [
          {required: true, message: '请选择流水类别'},
        ],
        desc: [
          {required: true, message: '请填写费用说明'},
        ],
        order_money: [
          {required: true, message: '请填写费用'},
        ],
        inout_type: [
          {required: true, message: '请选择流水类型'},
        ],
      },
      flow_category: [],
      common: {
        list_loading: false,
      }
    };
  },
  methods: {
    getFileList(val) {
      this.create.item.certificate = [];
      for (let i = 0; i < val.length; i++) {
        this.create.item.certificate[i] = {};
        this.create.item.certificate[i].url = val[i].url;
      }
    },
    //查看图片
    imgShow(value, img_info) {
      this.$refs.image_preview.imgPreview(value, img_info);
    },

    getEditCreateTime(value, type) {
      this.create.item.flow_time = value;
    },
    edit(row) {
      this.view.modal = false;
      this.create.item = row;
      this.create.model_title = '修改流水信息';
      this.uploadParam.key += 1;
      this.uploadParam.prefix = this.create.item.flow_id;
      this.uploadParam.list = JSON.parse(JSON.stringify(this.create.item.certificate));

      this.create.modal = true;
      this.create.modal_loading = false;
    },
    deleteInfo(row) {
      this.$Modal.confirm({
        title: "删除杂项流水信息",
        content: '删除杂项流水信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            id: row.id,
            flow_id: row.flow_id
          };
          this.$axios.post('/api/finance/deleteOtherFlowLog', param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getMainList();
            } else {
              this.$Message.error(response.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    validFlowStatus() {
      this.view.modal_loading = true;

      let param = {
        id: this.view.item.id,
        flow_id: this.view.item.flow_id,
      };

      this.$axios.post('/api/finance/validFlowStatus', param).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.view.modal = false;
          this.getMainList();
        } else {
          this.$Message.error(response.data.err_msg);
          this.view.modal_loading = false;
        }
      });
    },
    createOtherOrder(name) {
      this.create.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.create.item.order_money <= 0) {
            this.$Message.error('流水费用不能小于等于0的数');
            this.create.modal_loading = false;
            return;
          }

          if (this.create.item.extra_money < 0) {
            this.$Message.error('流水额外费用不能为负数');
            this.create.modal_loading = false;
            return;
          }


          let param = new FormData(); //创建form对象
          param.append('certificate', JSON.stringify(this.create.item.certificate));
          param.append('id', this.create.item.id);
          param.append('flow_id', this.create.item.flow_id);
          param.append('extra_money', this.create.item.extra_money);
          param.append('order_money', this.create.item.order_money);
          param.append('inout_type', this.create.item.inout_type);
          param.append('desc', this.create.item.desc);
          param.append('pay_type', this.create.item.pay_type);
          param.append('category_id', this.create.item.category_id);

          if (this.create.item.flow_time) {
            param.append('flow_time', this.create.item.flow_time);
          }

          let config = {
            headers: {'Content-Type': 'multipart/form-data'}
          };  //添加请求头

          this.$axios.post('/api/finance/createOtherFlowOrder', param, config)
              .then((response) => {
                if (response.data.err_no == 0) {
                  this.$Message.success(response.data.results);
                  this.create.modal = false;
                  this.getMainList();
                } else {
                  this.$Message.error(response.data.err_msg);
                  this.create.modal_loading = false;
                }

              })
              .catch((error) => {
                this.$Message.error('操作失败');
                this.create.modal_loading = false;
              });
        } else {
          this.create.modal_loading = false;
        }
      });
    },
    createOrder(name) {
      //创建
      this.$refs[name].resetFields();
      this.create.modal_loading = false;
      this.uploadParam.key += 1;
      this.uploadParam.list = []
      this.getFlowId();
      this.create.modal = true;
    },
    getFlowId() {
      this.$axios.get('/api/finance/getFlowId')
          .then((response) => {
            if (response.data.err_no == 0) {
              this.create.item.flow_id = response.data.results.flow_id;
              this.uploadParam.prefix = this.create.item.flow_id;
            }
          });
    },
    //获取状态的颜色
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'color:#19be6b';
          break;
        case 0:
          rs = 'color:#c5c8ce';
          break;
        case 2:
          rs = 'color:#ed4014';
          break;
        default:
          rs = '';
          break;
      }
      return rs;
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '有效';
          break;
        case 2:
          rs = '待生效';
          break;
        case 0:
          rs = '无效';
          break;
        default:
          rs = '';
          break;
      }
      return rs;
    },
    //获取结算状态颜色
    getPayTypeColor(index) {
      let rs = '';
      switch (index) {
        case 1:
        case 3:
          rs = 'color:#2775ff';
          break;
        case 4:
          rs = 'color:#ff9900';
          break;
        case 2:
        default:
          rs = 'color:#ed4014';
          break;
      }
      return rs;
    },
    getPayTypeText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '现金结算';
          break;
        case 2:
          rs = '未结算(记账)';
          break;
        case 3:
          rs = '已结算(记账)';
          break;
        case 4:
          rs = '记账退货';
          // rs = '记账退货，无需结算';
          break;
      }
      return rs;
    },
    searchSubmit() {
      this.list.page = 1;
      this.getMainList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getMainList();
    },
    //获取流水单信息
    getMainList() {
      this.common.list_loading = true;
      let param = {
        page: this.list.page,
        size: this.list.page_size,
        status: this.formItem.status,
        pay_type: this.formItem.pay_type,
        created_at: this.formItem.created_at,
        inout_type: this.formItem.inout_type,
        category_type: this.formItem.category_type
      };
      this.$axios.post('/api/finance/getOtherFlowList', param).then((res) => {
        this.common.list_loading = false;
        if (res.data.err_no == 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].extra_money = parseFloat(res.data.results.list[i].extra_money);
            res.data.results.list[i].order_money = parseFloat(res.data.results.list[i].order_money);
            res.data.results.list[i].money = parseFloat(res.data.results.list[i].money);
          }
          this.list.data = res.data.results.list;
          this.list.extra = res.data.results.extra;
          this.list.total = res.data.results.total;
          this.list.page_size = res.data.results.size;
        }
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    pageSizeChange(page_size) {
      this.list.page_size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getMainList();
    },
    //记账结算
    finishSaleSettlement(row) {
      this.$Modal.confirm({
        title: "记账结算",
        content: '确定将该订单的 <span style="color:#ed4014;">记账未结算状态</span> 改为 <span style="color:#ed4014;">记账已结算状态</span> ？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.$axios.post('/api/finance/finishSaleSettlement', {id: row.id}).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getMainList();
            } else {
              this.$Message.error(response.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    handleResetViewItem(item) {
      this.view.modal = false;
      this.create.modal = false;
    },
    //查看
    viewItem(row) {
      this.view.modal = true;
      this.view.modal_loading = false;
      this.view.item = row;
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.formItem.created_at = dateRange;
      } else {
        this.formItem.created_at = "";
      }

      this.searchSubmit()
    },
    //获取流水类别
    getFlowCategory() {
      this.$axios.get('/api/setting/getFlowCategoryList?size=200')
          .then((response) => {
            if (response.data.err_no == 0) {
              this.flow_category = response.data.results.list;
              this.filter.orderCategoryType = response.data.results.list;
            }
          });
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.page_size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  mounted() {
    this.setSelectedPageSize();
    this.clientFormItembak = this.clientFormItem;
    this.getMainList();
    this.getFlowCategory();
  },
  components: {
    ImageUpload,
    ImagePreview
  },
  created() {
    this.aCheck.plateName = 'financial_center';
  },
};
</script>

<style scoped>
#other_settlement {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#other_settlement_list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#other_settlement_list .page {
  margin-top: 16px;
}
</style>
<style>
#other_settlement .ivu-card {
  background-color: #fafafa;
}

#other_settlement .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#other_settlement .ivu-form-item {
  margin-bottom: 0;
}

#other_settlement_list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#other_settlement_list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#other_settlement_list .ivu-page {
  float: right;
}

#other_settlement_list .ivu-table-wrapper {
  height: auto;
}

#other_settlement_list .ivu-table-fixed-body {
  min-height: auto;
}

#other_settlement_list .ivu-table-overflowX, #other_settlement_list .ivu-table-tip {
  overflow-x: hidden;
}

#other_settlement_list .ivu-spin-fix {
  top: 40px !important;
  height:calc(100% - 40px) !important;
}

#other_settlement_list .ivu-table-wrapper {
  overflow: visible;
}
</style>
