<template>
  <!--图片预览-->
  <Modal v-model="img_show.modal" width="720" title="图片预览" footer-hide>
    <Carousel v-model="img_show.value" v-if="img_show.modal">
      <CarouselItem v-for="item in img_show.img_info" v-if="img_show.img_info" class="align-center">
        <img :src="item.url" alt="" class="pointer" style="max-height:560px;max-width: 640px">
      </CarouselItem>
    </Carousel>
  </Modal>
</template>

<script>
export default {
  name: "ImagePreview",
  data() {
    return {
      img_show: {
        modal: false,
        value: 0,
        img_info: [],
      },
    }
  },
  methods: {
    imgPreview(value, img_info) {
      this.img_show.value = value;
      this.img_show.modal = true;
      this.img_show.img_info = img_info;
    },
  },
}
</script>

<style scoped>

</style>
