import $axios from '@/plugins/http'

export const oss = {
    obj:[],

    accessid: '',
    policyBase64: '',
    signature: '',
    callbackbody: '',
    key: '',
    expire: 0,
    upload_type: '',
    module_type: '',

    host: '',
    thumbnail_url:'?x-oss-process=image/resize,m_mfit,h_100,w_100',//缩略图
    getSignature(param) {
        $axios.post('/api/common/getUploadToken', param).then(res => {
            let tmp = {};
            tmp.policyBase64 = res.data.results.policy;
            tmp.accessid = res.data.results.accessid;
            tmp.signature = res.data.results.signature;
            tmp.expire = parseInt(res.data.results.expire);
            tmp.callbackbody = res.data.results.callback;
            tmp.upload_type = param.type || '';
            tmp.module_type = param.module_type || '';
            tmp.host = res.data.results.host;
            tmp.key = res.data.results.dir;

            if (param.label != undefined) {
                this.obj[param.label] = tmp;
            }

            this.policyBase64 = res.data.results.policy;
            this.accessid = res.data.results.accessid;
            this.signature = res.data.results.signature;
            this.expire = parseInt(res.data.results.expire);
            this.callbackbody = res.data.results.callback;
            this.upload_type = param.type || '';
            this.module_type = param.module_type || '';
            this.host = res.data.results.host;
            this.key = res.data.results.dir;
        });
    },
    ossInfo(filename = null, label= false) {
        return {
            'host': label !== false ? this.obj[label].host : this.host,
            'key': (label !== false ? this.obj[label].key:this.key) + '/' + filename,
            'policy': label !== false ?this.obj[label].policyBase64 : this.policyBase64,
            'OSSAccessKeyId': label !== false ? this.obj[label].accessid : this.accessid,
            'success_action_status': '200', //让服务端返回200,不然，默认会返回204
            'callback': label !== false ? this.obj[label].callbackbody : this.callbackbody,
            'signature': label !== false ? this.obj[label].signature : this.signature,
        };
    }
}
