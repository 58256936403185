<template id="menu">
  <Menu theme="light" :active-name="active_name" @on-select="onselect" width="auto">
    <MenuGroup title="财务结算" v-if="aCheck.moduleCheck('financial_settlement')">
      <MenuItem name="1" v-if="aCheck.pageCheck('financial_overview')">
        <Icon type="ios-calculator"/>
        财务概览
      </MenuItem>
<!--      <MenuItem name="8" v-if="aCheck.pageCheck('financial_overview')">-->
<!--        <Icon type="ios-calculator"/>-->
<!--         应收汇总-->
<!--      </MenuItem>-->
<!--      <MenuItem name="9" v-if="aCheck.pageCheck('financial_overview')">-->
<!--        <Icon type="ios-calculator"/>-->
<!--        应付汇总-->
<!--      </MenuItem>-->
    </MenuGroup>
    <MenuGroup title="额外结算" v-if="aCheck.moduleCheck('extra_settlement')">
      <MenuItem name="6" v-if="aCheck.pageCheck('miscellaneous_settlement')">
        <Icon type="ios-cube"/>
        杂项结算
      </MenuItem>
    </MenuGroup>
    <MenuGroup title="进销流水" v-if="aCheck.moduleCheck('invoicing_settlement')">
      <MenuItem name="2" v-if="aCheck.pageCheck('purchase_settlement')">
        <Icon type="ios-basket"/>
        采购流水单
      </MenuItem>
      <MenuItem name="3" v-if="aCheck.pageCheck('sales_settlement')">
        <Icon type="logo-usd"/>
        销售流水单
      </MenuItem>
      <MenuItem name="4" v-if="aCheck.pageCheck('purchase_return_settlement')">
        <Icon type="logo-dropbox"/>
        采购退货流水单
      </MenuItem>
      <MenuItem name="5" v-if="aCheck.pageCheck('sales_return_settlement')">
        <Icon type="logo-dropbox"/>
        销售退货流水单
      </MenuItem>
      <MenuItem name="7" v-if="aCheck.pageCheck('logistics_settlement')">
        <Icon type="md-subway" />
        运费流水单
      </MenuItem>
    </MenuGroup>
  </Menu>
</template>

<script>
import * as loginUtil from '@/utils/login'

export default {
  name: "FinanceMenu",
  data() {
    return {
      active_name: this.active,
      menu_name: this.menuName,
    };
  },
  methods: {
    onselect(name) {
      this.$emit('func', name)
      localStorage.setItem(this.menu_name, name);
      loginUtil.setExpireTime();
    },
    setNavInfo(name) {
      this.active_name = name.toString()
    }
  },
  created() {
    this.aCheck.plateName = 'financial_center';
  },

  props: ["active","menuName"],
};
</script>

<style scoped>
#menu {
  width: 200px;
  position: absolute;
  left: 0;
}
</style>
